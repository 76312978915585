import { render, staticRenderFns } from "./shopData.vue?vue&type=template&id=796afb4a&scoped=true"
import script from "./shopData.vue?vue&type=script&lang=js"
export * from "./shopData.vue?vue&type=script&lang=js"
import style0 from "./shopData.vue?vue&type=style&index=0&id=796afb4a&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "796afb4a",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\bak\\banling-web-front\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('796afb4a')) {
      api.createRecord('796afb4a', component.options)
    } else {
      api.reload('796afb4a', component.options)
    }
    module.hot.accept("./shopData.vue?vue&type=template&id=796afb4a&scoped=true", function () {
      api.rerender('796afb4a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/data/shopData.vue"
export default component.exports